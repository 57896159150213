<div class="container">
  <div class="row">
    <form (ngSubmit)="updateDQSettings()" [formGroup]="form">
      <div class="col-xs-12 col-sm-11 col-md-8 center-content">
        <div class="row align-center">
          <div class="col-md-6">
            <label>Translation Services Detection</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the Translation Services Detection
                globally.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              formControlName="enableTranslationServiceDetection"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Clipboard Detection</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the Clipboard Detection globally.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              formControlName="enableClipboardDetection"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Data Quality Questions</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the Data Quality Battery globally.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              formControlName="enableDataQuality"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Max Number Of Data Quality Questions</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can define the number of Data Quality questions added
                to the PS Screener.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <input
              type="number"
              name="maxDataQualityQuestionCount"
              formControlName="maxDataQualityQuestionCount"
            />
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Terminate Low Quality Questions</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This features enables the Data Quality Question Battery
                validation.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableDQBlackListTerm'"
              formControlName="enableDQBlackListTerm"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Number of Low Quality Questions to Terminate</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can define the number of Data QualityQuestions a user
                is allowed to fail without terminating.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <input
              type="number"
              name="maxDQFailCount"
              formControlName="maxDQFailCount"
            />
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Drag and Drop Question</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                If this setting is switched off, the drag and drop question
                won’t be part of the DQ battery.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableDragNDrop'"
              formControlName="enableDragNDrop"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Honey Pot Question</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the Honey Pot question globally.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableHoneyPot'"
              formControlName="enableHoneyPot"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>PS Device Fingerprinting</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables our internal device fingerprinting service
                globally.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enablePSDeviceFingerprinting'"
              formControlName="enablePSDeviceFingerprinting"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <!-- 
        Respondent Identification Service
        -->

        <div class="row align-center">
          <div class="col-md-6">
            <label>Respondent Identification Service</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables our internal Respondent Identification
                Service globally.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableRespondentIdentificationService'"
              formControlName="enableRespondentIdentificationService"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>

        <div class="row align-center">
          <div class="col-md-6">
            <label>Verisoul</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the Verisoul API integration globally.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableVerisoul'"
              formControlName="enableVerisoul"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>PS Captcha Enable</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the second version of the image question.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enablePSCaptcha'"
              formControlName="enablePSCaptcha"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Impossible Travel</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the impossible travel service.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableImpossibleTravelTermination'"
              formControlName="enableImpossibleTravelTermination"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Multi-Session Detection</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the multi-session detection service.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableMultiSessionDetection'"
              formControlName="enableMultiSessionDetection"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Inconsistent Network Connection Speed</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the Inconsistent Network Connection service.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableInconsistentNetworkTermination'"
              formControlName="enableInconsistentNetworkTermination"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>IP Address Mismatch</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the IP Address Mismatch service.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enableIPMismatchTermination'"
              formControlName="enableIPMismatchTermination"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>PureText Daily Sweep</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                This feature enables the PureText Daily Sweep service.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-capsule-radio-button
              class="capsule-button-row"
              [id]="'enablePureTextDailySweep'"
              formControlName="enablePureTextDailySweep"
            >
            </ps-ui-capsule-radio-button>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Localizations where DQ is Enabled</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can define the countries the Data Quality Battery
                should be enabled for.
              </p>
            </div>
          </div>
          <div class="col-md-3">
            <ps-ui-select-dropdown
              [dropdownItems]="countryList"
              [field]="'name'"
              [enableSearch]="true"
              labelNone="Country"
              (selectedItem)="selectCountry($event)"
            >
            </ps-ui-select-dropdown>
          </div>
          <div class="col-md-3">
            <ps-ui-select-dropdown
              #dqEligibleLanguageSelect
              [disabled]="!dqEligibleCountry"
              [multi]="true"
              [dropdownItems]="dqEligibleCountry?.lang || []"
              [field]="'name'"
              [enableSearch]="true"
              [loading]="false"
              labelNone="Languages"
              [label]="'Languages'"
              (selectedItems)="selectDQEligibleLanguages($event)"
            >
            </ps-ui-select-dropdown>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Country Data Quality Trigger</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can define the countries which should show the Data
                Quality Battery to every user.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-select-dropdown
              #countryTriggerSelect
              [multi]="true"
              [dropdownItems]="triggerCountries"
              [field]="'name'"
              [enableSearch]="true"
              labelNone="Country"
              [label]="'D.Q. Trigger Countries'"
              (selectedItems)="selectTriggerCountries($event)"
            >
            </ps-ui-select-dropdown>
          </div>
        </div>
        <div>
          <div class="row align-center">
            <div class="col-md-6">
              <label>Country/Supplier Data Quality Trigger</label>
              <div class="setting-info">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <p style="white-space: normal">
                  Here you can define country and supplier combinations which
                  should show the Data Quality Battery to every user.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <ps-ui-select-dropdown
                [dropdownItems]="countrySupplierCountries"
                [field]="'name'"
                [enableSearch]="true"
                [labelNone]="'No Trigger Countries'"
                [label]="selectedCountryForSupplier?.name || 'Pick country'"
                [loading]="false"
                (selectedItem)="selectCountryForSupplier($event)"
              >
              </ps-ui-select-dropdown>
            </div>
          </div>
          <div
            *ngIf="!!selectedCountryForSupplier"
            class="border my-2 p-2 d-flex flex-column gap-3"
          >
            <div class="m-auto p-2 border">
              <span>{{ selectedCountryForSupplier?.name || 'missing' }}</span>
            </div>
            <div
              class="d-flex flex-row justify-content-around align-items-center"
            >
              <span>Country/Supplier Data Quality Trigger</span>
              <ps-ui-select-dropdown
                #countrySupplierSelect
                class="col-md-6"
                labelNone="Select suppliers"
                [multi]="true"
                [dropdownItems]="suppliers"
                [field]="'name'"
                [enableSearch]="true"
                (selectedItems)="selectSuppliersForCountry($event)"
              >
              </ps-ui-select-dropdown>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">
              <label>Desktop/Supplier Data Quality Trigger</label>
              <div class="setting-info">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <p style="white-space: normal">
                  Here you can define the suppliers you want to enable the DQ
                  for Desktop users for.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <ps-ui-select-dropdown
                #desktopSupplierSelect
                [dropdownItems]="suppliers"
                [field]="'name'"
                [enableSearch]="true"
                labelNone="Select Supplier"
                [multi]="true"
                (selectedItems)="selectSupplierForDesktopTrigger($event)"
              >
              </ps-ui-select-dropdown>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">
              <label>AT Qualification Trigger</label>
              <div class="setting-info">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <p style="white-space: normal">
                  Here you can select the Advanced Targeting questions which
                  will enable the DQ Battery.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <ps-ui-select-dropdown
                #atQualificationTriggerSelect
                [dropdownItems]="qualificationList"
                [field]="'name'"
                labelNone="Pick Qualification"
                [multi]="true"
                (selectedItems)="selectQualificationTrigger($event)"
              >
              </ps-ui-select-dropdown>
            </div>
          </div>
          <div class="row align-center">
            <div class="col-md-6">
              <label>Account Level Data Quality Trigger</label>
              <div class="setting-info">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <p style="white-space: normal">
                  Here you can define Buyer accounts which want to show the DQ
                  battery on every transaction before getting into a survey they
                  created.
                </p>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <ps-ui-select-dropdown
                #accountLevelTriggerSelect
                [dropdownItems]="buyers"
                [field]="'name'"
                [enableSearch]="true"
                labelNone="Select Buyer"
                [multi]="true"
                (selectedItems)="selectAccountLevelTrigger($event)"
              >
              </ps-ui-select-dropdown>
            </div>
          </div>
        </div>
        <!-- PSID Upload -->
        <div class="row align-center">
          <div class="col-md-6">
            <label>PSID Blacklist/Whitelist Upload</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can upload PSIDs that need to be
                Blacklisted/Whitelisted. Limit: 200 PSIDs
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-flex align-items-center">
              <div class="position-relative upload-file me-2">
                <input
                  type="file"
                  [hidden]="true"
                  class="file-input"
                  accept=".csv, .xls, .xlsx"
                  (change)="onFileChange($event, psidUpload)"
                  #fileUpload
                />
                <input
                  id="filePath"
                  type="text"
                  name="fileInput"
                  [value]="psidUpload.filename"
                  placeholder=".csv, .xlsx"
                  class="input-md p-1"
                  (click)="fileUpload.value = ''; fileUpload.click()"
                  readonly
                />
              </div>
              <button
                class="button-psid-upload"
                type="button"
                psUiButton
                (click)="uploadPSIDs()"
              >
                Upload
              </button>
            </div>

            <div>
              <span class="form-text">
                <a
                  target="_blank"
                  href="assets/templates/psid-upload-template.csv"
                >
                  Download Template
                </a>
              </span>
            </div>
          </div>
        </div>
        <!-- OE Question Upload -->
        <div class="row align-center">
          <div class="col-md-6">
            <label>PS Screener OE V2 Upload</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can upload OE questions to be used by the PS Screener
                V2.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-flex align-items-center">
              <div class="position-relative upload-file me-2">
                <input
                  type="file"
                  [hidden]="true"
                  class="file-input"
                  accept=".csv, .xls, .xlsx"
                  (change)="onFileChange($event, oeUpload)"
                  #oeFileUpload
                />
                <input
                  id="filePath"
                  type="text"
                  name="fileInput"
                  [value]="oeUpload.filename"
                  placeholder=".csv, .xlsx"
                  class="input-md p-1"
                  (click)="oeFileUpload.value = ''; oeFileUpload.click()"
                  readonly
                />
              </div>
              <button
                class="button-psid-upload"
                type="button"
                psUiButton
                (click)="uploadOEQuestions()"
              >
                Upload
              </button>
            </div>
            <div>
              <span class="form-text">
                <a
                  target="_blank"
                  href="assets/templates/oe-question-upload-template.xlsx"
                >
                  Download Template
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 d-flex align-items-center">
            <label>Editable Purescore Threshold</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can define the PureScore Threshold per country.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <ps-ui-select-dropdown
                #_purescoreThresholdCountrySelect
                class="col-md-5"
                [dropdownItems]="purescoreThresholdCountries"
                [field]="'name'"
                [enableSearch]="true"
                [loading]="purescoreThresholdCountries.length === 0"
                (selectedItem)="selectThresholdCountry($event)"
              >
              </ps-ui-select-dropdown>
              <ps-ui-select-dropdown
                #_purescoreThresholdLanguageSelect
                class="col-md-4"
                [dropdownItems]="purescoreThresholdCountry?.lang || []"
                [field]="'name'"
                [disabled]="isPurescoreThresholdDisabled"
                [loading]="false"
                (selectedItem)="selectThresholdLanguage($event)"
              >
              </ps-ui-select-dropdown>
              <div class="d-flex col-md-2">
                <input
                  type="number"
                  class="form-control col-md-1"
                  [formControl]="purescoreThresholdControl"
                  max="10"
                  min="0"
                />
                <div class="d-flex flex-column border">
                  <span class="btn" (click)="purescoreThresholdToggle()"
                    ><i class="fa fa-pencil"></i
                  ></span>
                  <span class="btn" (click)="confirmPurescoreThreshold()"
                    ><i class="fa fa-check" style="color: green"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-center">
          <div class="col-md-6">
            <label>Countries where Device Intelligence is enabled</label>
            <div class="setting-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <p style="white-space: normal">
                Here you can define the countries which should process User
                Identification from Device Intelligence.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <ps-ui-select-dropdown
              #deviceIntelligenceCountriesSelect
              [multi]="true"
              [dropdownItems]="countryList"
              [field]="'name'"
              [enableSearch]="true"
              labelNone="Country"
              [label]="'Device Intelligence Enabled Countries'"
              (selectedItems)="selecteDeviceIntelligenceCountries($event)"
            >
            </ps-ui-select-dropdown>
          </div>
        </div>
      </div>
      <div class="hasing-buttons mt-6 col-md-2">
        <button type="submit" color="primary">Submit</button><br />
      </div>

      <div class="backbutton col-md-12 mt-6">
        <button
          id="dashboard"
          type="button"
          psUiButton
          size="large"
          color="primary"
          [routerLink]="['/dashboard/']"
        >
          Back to Dashboard
        </button>
      </div>
    </form>
  </div>
</div>
